<template>
  <div>
    <b-form class="mt-1">
      <b-card>
        <b-card-title>
          <h4 class="mb-0 ml-50 text-primary">{{ $t('Genel') }}</h4>
        </b-card-title>
        <b-row>
          <!-- Field: Ad -->
          <b-col cols="12" md="6" lg="4">
            <b-form-group :label="$t('Dil')">
              <v-select
                v-model="sayfa.sDilKodu"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="dilOptions"
                :reduce="(val) => val.value"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12" md="6" lg="4">
            <b-form-group :label="$t('Tür')">
              <v-select
                v-model="sayfa.webSiteSayfaTur"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="webSiteSayfaTurOptions"
                :reduce="(val) => val.value"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12" md="6" lg="4">
            <b-form-group :label="$t('Aktif Durumu')">
              <b-form-checkbox
                v-model="sayfa.isActive"
                :checked="sayfa.isActive"
                class="custom-control-success"
                name="check-button"
              >
                {{ $t('Aktif/Pasif') }}
              </b-form-checkbox>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" md="6" lg="4">
            <b-form-group :label="$t('Başlık')">
              <b-form-input
                v-model="sayfa.sBaslik"
                type="text"
                :placeholder="$t('Başlık Giriniz')"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6" lg="4">
            <b-form-group label="URL">
              <b-form-input
                v-model="sayfa.sUrl"
                type="url"
                :placeholder="$t('URL Giriniz')"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6" lg="4">
            <b-form-group label="URL">
              <b-form-input v-model="url" type="text" disabled />
            </b-form-group>
          </b-col>
        </b-row>
      </b-card>
      <b-card>
        <b-tabs pills>
          <b-tab :title="$t('Görünüm')" active>
            <b-card>
              <b-row>
                <b-col>
                  <b-row>
                    <b-col cols="12" md="6" lg="6">
                      <b-form-group :label="$t('Yayınlayan')">
                        <b-form-input
                          v-model="sayfa.sYayinlayan"
                          type="text"
                          :placeholder="$t('Yayınlayan')"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" md="6" lg="6">
                      <b-form-group :label="$t('Yayın Tarihi')">
                        <b-form-input
                          type="date"
                          v-model="sayfa.dtOlusTarih"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="12" md="12" lg="12">
                      <b-form-group>
                        <b-form-tags
                          input-id="tags"
                          :input-attrs="{
                            'aria-describedby': 'tags-remove-on-delete-help',
                          }"
                          :placeholder="$t('Etiketleri enter ile giriniz')"
                          remove-on-delete
                          v-model="sayfa.sEtiketler"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>

                <!-- Resim -->
                <b-col
                  cols="12"
                  md="4"
                  class="d-flex justify-content-between flex-column"
                >
                  <!-- User Avatar & Action Buttons -->
                  <div
                    class="bg-light-primary rounded-top text-center page-img-box"
                  >
                    <b-img :src="sayfa.sResimUrl" height="160" />
                    <b-button
                      variant="outline-primary btn-image-update"
                      class="mt-0 mt-md-2"
                      @click="openUpload()"
                    >
                      <feather-icon icon="PaperclipIcon" class="mr-25" />
                      <span>{{
                        !sayfa.sResimUrl ? $t('Ekle') : $t('Değiştir')
                      }}</span>
                    </b-button>
                  </div>
                </b-col>
              </b-row>
              <b-row class="mt-1">
                <b-col cols="12" md="12" lg="12">
                  <label>{{ $t('İçerik') }}</label>
                  <editor
                    v-model="sayfa.sIcerik"
                    api-key="'qagffr3pkuv17a8on1afax661irst1hbr4e6tbv888sz91jc'"
                    :init="{
                      plugins: [
                        'print preview paste importcss searchreplace autolink autosave save directionality code fullscreen image link media template table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap uploadimage',
                      ],
                      toolbar:
                        'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent | numlist bullist checklist | forecolor backcolor casechange permanentpen formatpainter removeformat colorpicker | pagebreak | charmap | fullscreen  preview  print | insertfile image media pageembed link anchor | a11ycheck ltr rtl | showcomments addcomment | uploadimage',
                      language: 'tr_TR',
                      min_height: 500,
                      valid_elements: '*[*]',
                      extended_valid_elements: 'style,link[href|rel]',
                      custom_elements: 'style,link,~link',
                      force_br_newlines: true,
                      forced_root_block: false,
                    }"
                  />
                </b-col>
              </b-row>
            </b-card>
          </b-tab>
          <b-tab :title="$t('Gizli Alanlar')">
            <b-card>
              <b-form-group :label="$t('Açıklama')">
                <b-form-textarea
                  id="textarea-aciklama"
                  :placeholder="$t('Açıklama')"
                  v-model="sayfa.sAciklama"
                  rows="8"
                />
              </b-form-group>
              <b-form-group label="Script">
                <b-form-textarea
                  id="textarea-script"
                  placeholder="Script"
                  v-model="sayfa.sScript"
                  rows="8"
                />
              </b-form-group>
              <b-form-group label="Style">
                <b-form-textarea
                  id="textarea-style"
                  placeholder="Style"
                  v-model="sayfa.sStyle"
                  rows="8"
                />
              </b-form-group>
            </b-card>
          </b-tab>
        </b-tabs>
      </b-card>
      <b-card>
        <div class="demo-inline-spacing">
          <save-button
            :showSaveAndClose="false"
            :onClickSave="saveSayfa"
            :onClickClose="goBackToList"
          />
          <b-button class="mt-3" variant="danger" @click="resetForm">
            <feather-icon icon="RefreshCwIcon" class="mr-50" size="16" />
            {{ $t('Sıfırla') }}
          </b-button>
        </div>
      </b-card>
    </b-form>
  </div>
</template>

<script>
import Editor from '@tinymce/tinymce-vue';
import { inputImageRenderer } from '@core/comp-functions/forms/form-utils';
import { getCurrentDate, apiToDate } from '@core/utils/filter';
// Save Button
import SaveButton from '@/components/SaveButton.vue'
// Alert
import AlertService from '@/common/alert.service'
// Options
import StaticOptions from '@/common/options/StaticOptions';
// Toast
import ToastificationSuccess from '@core/components/toastification/ToastificationSuccess.vue'

export default {
  components: {
    Editor,
    SaveButton,
  },
  data() {
    return {
      sayfa: {
        id: 0,
        sUrl: '',
        sBaslik: '',
        sAciklama: '',
        sStyle: '',
        sScript: '',
        sIcerik: '',
        sResimUrl: '',
        sYayinlayan: '',
        sEtiketler: [],
        dtOlusTarih: getCurrentDate(),
        sDilKodu: 'tr',
        isActive: true,
        webSiteSayfaTur: 1,

      },
      webSiteSayfaTurOptions: StaticOptions.webSiteSayfaTurOptions,
      dilOptions: StaticOptions.dilOptions,
      form: new FormData(),
    }
  },
  computed: {
    url() {
      return this.urlFormat(this.sayfa.sUrl);
    },
  },
  methods: {
    fetchSayfa() {
      const paramId = this.$router.currentRoute.params.id;
      if (parseInt(paramId, 10) !== -1) {
        this.$store.dispatch('sayfaYonetim/fetchSayfa', paramId)
          .then(response => {
            if (response.statusCode === 200) {
              for (const prop in response.result) {
                if (response.result[prop] === 'null') {
                  response.result[prop] = '';
                }
              }
              // Tag alanı array istiyor, kaydedilirken de ',' ile kaydoluyor
              if (response.result.sEtiketler) response.result.sEtiketler = response.result.sEtiketler.split(',');
              response.result.dtOlusTarih = apiToDate(response.result.dtOlusTarih);
              this.sayfa = response.result;
            } else {
              AlertService.error(this, response.message)
            }
          })
      }
    },

    saveSayfa() {
      const check = this.sayfa.sDilKodu === ''
        || this.sayfa.sUrl === ''
        || this.sayfa.sBaslik === ''
        || this.sayfa.webSiteSayfaTur == null
      if (check) {
        AlertService.warning(this, 'Genel alanını doldurun');
        return;
      }

      // Form alanlarini ekle 
      for (const prop in this.sayfa) {
        if (typeof this.sayfa[prop] === 'string') {
          this.form.set(prop, this.sayfa[prop] || '')
        } else {
          this.form.set(prop, this.sayfa[prop])
        }
      }
      this.form.set('sUrl', this.url);
      this.form.set('folderName', 'sayfa-resim');

      // Gonder
      this.$store.dispatch('sayfaYonetim/saveSayfa', this.form)
        .then(response => {
          if (response.statusCode === 200) {
            this.$toast({
              component: ToastificationSuccess,
              position: 'top-right',
              props: { text: response.message },
            })

            this.$router.push(`/management/sayfa/save/${response.result}`)
              .then(() => {
                this.fetchSayfa();
              })
          } else {
            AlertService.error(this, response.message)
          }
        });
    },

    openUpload() {
      const fileInput = document.createElement('input');
      fileInput.type = 'file';
      fileInput.accept = 'image/png, image/jpeg'
      fileInput.addEventListener('change', event => {
        this.form = new FormData();
        this.form.set('file', event.target.files[0]);
        inputImageRenderer(event, base64 => {
          this.sayfa.sResimUrl = base64;
        })
      })
      fileInput.click();
    },

    urlFormat(url) {
      if (!url) url = '';
      url = url.toLowerCase();
      const trToEng = {
        ö: 'o',
        ç: 'c',
        ğ: 'g',
        ü: 'u',
        ı: 'i',
        ş: 's',
      };

      for (const key in trToEng) {
        if (Object.hasOwnProperty.call(trToEng, key)) {
          url = url.replaceAll(key, trToEng[key]);
        }
      }
      return url.replaceAll(/\s+/g, '-').replaceAll(/[^a-zA-Z\d\s:-]/g, '')
    },
    goBackToList() {
      this.$router.push('/management/sayfa/');
    },
    resetForm() {
      this.sayfa = {
        id: 0,
        sUrl: '',
        sBaslik: '',
        sAciklama: '',
        sStyle: '',
        sScript: '',
        sIcerik: '',
        sResimUrl: '',
        sYayinlayan: '',
        sEtiketler: [],
        dtOlusTarih: getCurrentDate(),
        sDilKodu: 'tr',
        isActive: true,
        webSiteSayfaTur: 1,
      };
      this.form = new FormData();
    },
  },

  mounted() {
    this.fetchSayfa();
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.btn-image-update {
  position: absolute !important;
  bottom: 0px;
  right: 15px;
}

.page-img-box {
  height: 100%;
  width: auto;
}
</style>
